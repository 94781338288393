import React, {Component} from 'react';

const Stars = props => {
	return (
		<span>
			{[...Array(5)].map((e, i) => {
				if( (i+1) <= props.rating ) {
					return (
						<span className="icon icon-star-full" key={`star-`+i}></span>
					)
				} else {
					return (
						<span className="icon icon-star" key={`star-`+i}></span>
					)
				}
			})}
		</span>
	)
}

export default Stars
